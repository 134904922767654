import React from "react";

export function Navbar() {
  const [scrolled, setScrolled] = React.useState(false);

  React.useEffect(() => {
    window.addEventListener("scroll", () =>
      setScrolled(window.scrollY > 0 ? true : false)
    );
  }, []);

  return (
    <nav
      className={`sticky top-0 z-50 transition-all bg-white ${
        scrolled ? "shadow-sm border-b" : ""
      }`}
    >
      <div className="md:container mx-auto flex justify-center">
        <div className="max-w-[950px] w-full flex items-center justify-between px-4 md:px-0 py-3">
          <img src="/image/logo.png" alt="logo" className="h-8" />
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
